.login-form {
    background: var(--text-color-white);
    border: 1px solid var(--border-grey);
    border-radius: var(--border-radius);
    padding: 1em;
}

.login-form img {
    display: block;
    margin: auto;
}

.login-form h3 {
    margin-bottom: 1em;
}

input {
    border: none;
}