@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
    --background-white: #efefef;
    --border-grey: #c8c8c8;
    --text-color: #333333;
    --text-color-white: #fff;
    --border-radius: 5px;

    --first-color: #2CA1BD;
    --first-color-1: #7ACBDE;
    --first-color-2: #4DB4CC;
    --first-color-3: #0A91B0;
    --first-color-4: #036C85;

    --second-color: #3C5AC7;
    --second-color-1: #869AE3;
    --second-color-2: #5C76D4;
    --second-color-3: #1B3EBD;
    --second-color-4: #0F2B91;

    --third-color: #2CD358;
    --third-color-1: #7DE999;
    --third-color-2: #50DD75;
    --third-color-3: #06CB3A;
    --third-color-4: #00A02A;
}

html {
    font-family: 'Montserrat', sans-serif;
    height: 100%;
}

body {
    background: var(--background-white);
    color: var(--text-color);
    height: 100%;
}

main {
    display: flex;
    flex-direction: column;
    height: 100%;
}

input {
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: none;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid var(--border-grey);
}

button {
    width: 100%;
    border-radius: var(--border-radius);
    background: var(--first-color);
    color: var(--text-color-white);
    cursor: pointer;
    box-shadow: none;
    border: none;
    padding: 1em;
    text-shadow: 1px 1px 1px var(--text-color);
}

button:active {
    background: var(--first-color-1);
}

button:focus {
    background: var(--first-color-1);
}

button:hover {
    background: var(--first-color-3);
}

h1, h2, h3 {
    font-weight: bold;
}

#root {
    display: flex;
    height: inherit;
    flex-direction: column;
}


.m-t-1em {
    margin-top: 1em;
}


.hidden {
    visibility: hidden;
    opacity: 100%;
    height: 0;
    max-height: 0;
    overflow: hidden;
    margin: 0;
    display: none;
}


.navigation-button {
    border-radius: var(--border-radius);
    background: var(--second-color);
    color: var(--text-color-white);
    text-decoration: none;
    padding: 0.5em 1em;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: bold;
    margin-top: 1em;
}