.header {
    background: var(--first-color);
    color: var(--text-color-white);
    padding: 1em;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.company-name {
    margin-left: 1em;
    font-weight: bold;
    text-shadow: 1px 1px 2px var(--text-color);
}

.logo {
    max-height: 3rem;
}

.header-menu {
    align-self: center;
    justify-self: center;
    display: flex;
    flex-grow: 2;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
}

.header-menu span svg {
    cursor: pointer;
    fill: var(--text-color-white);
}