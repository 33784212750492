progress {
    width: 96vw;
    height: 1em;
    border-radius: var(--border-radius);
    border: 0;
    background: var(--text-color-white);

    margin: 2vw;
}

progress::-webkit-progress-bar {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress::-webkit-progress-value {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress::-moz-progress-bar {
    background: var(--text-color-white);
    border-radius: 9px;
}

progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Get rid of default border in Firefox. */
    border: none;

    border-radius: var(--border-radius);

    /* Dimensions */
    height: 1.5vh;
}

progress::-webkit-progress-value {
    background: var(--first-color);
    border-radius: var(--border-radius);
}

progress::-moz-progress-bar {
    background: var(--first-color);
    border-radius: var(--border-radius);
}
