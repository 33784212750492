.room {
    height: inherit;
    display: flex;
    flex-direction: column;
}

.room > main {
    justify-content: center;
}

.game-instance {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#gameInstance {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.game-canvas {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.unityprogress {
}
